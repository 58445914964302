import React, {useEffect, useState} from 'react';
import LayoutContainer from '../base/LayoutContainer';
import {Helmet} from 'react-helmet';
import {Button, Col, Container, Row} from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import '../post/PostPage.css';
import Form from 'react-bootstrap/Form';
import {Remarkable} from 'remarkable';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DOMPurify from 'dompurify';

const md = new Remarkable({
    html: true
});

const NewPost = (props) => {

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [tags, setTags] = useState("");
    const [key, setKey] = useState("");
    const [typedTag, setTypedTag] = useState('');
    const [addedTags, setAddedTags] = useState([]);

    const [bitFlip, setBitFlip] = useState(false);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get(`/base/api/ps`)
            .then(response => {
                const individualCategories = response.data.map(c => {
                    return c.tags.split(',').map(t => t.toLowerCase().trim());
                }).flat(1);
                setCategories([...new Set(individualCategories)].sort());
            })
            .catch(error => console.error(`There was an error retrieving the list: ${error}`))
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setAddedTags([...addedTags, typedTag]);
            setTypedTag('');
        }
    };

    useEffect(() => {
        setTags(addedTags.join(','));
    }, [addedTags]);

    const upload = () => {
        axios.post('/base/api/n',
            {"title": title, "content": content, "tags": tags, "pk": key}
        )
            .then(function (response) {
                console.log(response.data);
                window.location.href = `https://rohitkulky.com/blog/post/${response.data}`
            })
    }

    return (<LayoutContainer>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>New post...</title>
            <meta id="og-image" property="og:image" content="https://i.imgur.com/oLRVjF7.png"/>
            <meta id="og-title" property="og:title" content='New post...'/>
        </Helmet>
        <Container>
            <Row>
                <h3 className={"middleCenter"}>
                    Add new post
                </h3>
            </Row>
            <Row>
                <h6>
                    Title
                </h6>
                <Form.Control onChange={(e) => setTitle(e.target.value)} as="textarea" rows={1}/>
            </Row>
            <Row>
                <h6>
                    Content
                </h6>
                <Form.Control onChange={(e) => setContent(e.target.value)} as="textarea" rows={5}/>
            </Row>
            <Row>
                <h6>
                    Tags
                </h6>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Control value={typedTag}
                                      onChange={(e) => setTypedTag(e.target.value)}
                                      onKeyDown={handleKeyDown}
                                      list={"suggest"}/>
                        <datalist id="suggest">
                            {
                                categories.map((c, i) => {
                                    return <option key={i} value={c}>{c}</option>
                                })
                            }
                        </datalist>
                    </Form.Group>
                </Col>
                <Col md={'auto'}>
                    {
                        addedTags.map(t => {
                            return <Badge bg="danger" className={'m-1'}>{t}</Badge>
                        })
                    }
                </Col>
            </Row>
            <Row className="pt-3">
                <Col md={4}>
                    <Form.Control onChange={(e) => setKey(e.target.value)} type="password" placeholder="Password"/>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col className={"middleCenter"}>
                    <Button variant='danger' onClick={upload}>
                        Upload
                    </Button>
                </Col>
            </Row>
            <Row className='pt-5 pb-5 mt-5 mb-5'>
                <Link to="/">
                    <Button style={{fontSize: '1rem'}} variant="primary" type="submit">
                        Back Home
                    </Button>
                </Link>
            </Row>
            <hr/>
            <Row>
                <h5 className="middleCenter">
                    Preview
                </h5>
            </Row>
            <hr/>
            <Row>
                <div className="twelve columns middleCenter postMainTitle">
                    {title}
                </div>
            </Row>
            <Row className={'p-5'}>
                <div className="postMainContent">
                    <div dangerouslySetInnerHTML={{__html: md.render(DOMPurify.sanitize(content))}}/>
                </div>
            </Row>
        </Container>
    </LayoutContainer>);
}

export default NewPost;
