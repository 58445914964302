import React, {useEffect, useState} from 'react';
import LayoutContainer from '../base/LayoutContainer';
import axios from 'axios';
import './PostPage.css';
import {Remarkable} from 'remarkable';
import {Link} from 'react-router-dom';
import {Button, Col, Container, Row, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import DOMPurify from 'dompurify';

const initialPost = {
  title: '',
  content: '',
  pubDate: '',
  tags: '',
  pid: '',
};

const md = new Remarkable({
  html: true,
});

const PostPage = () => {

  const [post, setPost] = useState(initialPost);

  const postId = window.location.href.split('/')[5];

  useEffect(() => {
    if (postId !== undefined && postId !== '' && post.title === '') {
      axios.get(`/base/api/p?p=${postId}`).
          then(response => {
            console.log(response);
            setPost(response.data);
            document.title = post.title ?? '...';
          }).
          catch(error => console.error(
              `There was an error retrieving the post: ${error}`));
    }
  }, []);

  if (post && post.title === '') {
    return <LayoutContainer>
      <Container className={'middleCenter'}>
        <Spinner animation={'grow'} size={'lg'}/>
      </Container>
    </LayoutContainer>;
  }

  return (
      <LayoutContainer>
        <Container style={{width: 'max(50vw, 500px)'}}>
          <Helmet titleTemplate="%s">
            <meta charSet="utf-8"/>
            <title>{post.title ?? '...'}</title>
            <meta id="og-title" property="og:title"
                  content={post.title ?? '...'}/>
            <meta name="og:image" content={'https://i.imgur.com/XZcHFO5.jpeg'}/>
            <meta name="decription"
                  content={post.content.substring(0, 50) + '...'}/>
            <meta name="og:decription"
                  content={post.content.substring(0, 50) + '...'}/>
            <meta charSet="utf-8"/>
            <link rel="icon" href="https://i.imgur.com/XZcHFO5.jpg"/>
            <meta name="viewport"
                  content="width=device-width, initial-scale=1"/>
            <meta name="theme-color" content="#000000"/>
            <meta property="og:image:secure_url"
                  content="https://i.imgur.com/XZcHFO5.png"/>
            <link rel="canonical" href="https://www.example.com/"/>
            <link rel="apple-touch-icon"
                  href="https://i.imgur.com/XZcHFO5.jpg"/>
            <link rel="manifest" href="%PUBLIC_URL%/manifest.json"/>
            <meta name="description" content="Helmet application"/>
          </Helmet>
          <Row>
            <div className="twelve columns middleCenter postMainTitle"
                 style={{color: 'black'}}>
              {post.title}
            </div>
          </Row>
          <Row>
            <Col style={{
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: 'azure',
              textShadow: '0 0 1px black'
            }}>
              Rohit's blog
            </Col>
          </Row>
          <Row>
            <div className="postMainContent pt-4">
              <div className={'pt-4'} dangerouslySetInnerHTML={{
                __html: md.render(DOMPurify.sanitize(post.content)),
              }}/>
            </div>
          </Row>
          <Row>
            <Col style={{
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: 'azure',
              textShadow: '0 0 1px black'
            }}>
              {post.pubDate}
            </Col>
          </Row>
          <Row>
            <Col style={{display: 'flex', justifyContent: 'end'}}>
              {
                post.tags.split(',').map(t => {
                  return <Link style={{
                    textDecoration: 'none',
                    color: 'azure',
                    paddingRight: 5,
                    textShadow: '0 0 1px black'
                  }} key={t.trim()} to={'/blog/type/' + t.trim().toLowerCase()}> {t.trim().toLowerCase()} </Link>;
                })
              }
            </Col>
          </Row>
          <Row className="pt-5 pb-5 mt-5 mb-5">
            <Link to="/">
              <Button style={{fontSize: '1rem'}}
                      variant="outline-light" type="submit">
                Back Home
              </Button>
            </Link>
          </Row>
        </Container>
      </LayoutContainer>
  )
      ;
};

export default PostPage;
