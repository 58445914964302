import React, {useEffect, useState} from 'react';
import LayoutContainer from '../base/LayoutContainer';
import './BlogHome.css';
import {Button, Col, Container, Row, Spinner} from 'react-bootstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import PostTable2, {hexColors} from './list/PostTable2';

const BlogHome = (props) => {
      const initialPosts = [];

      const randomColor = hexColors[Math.floor(Math.random() * hexColors.length)];

      const [categories, setCategories] = useState([]);

      const category = window.location.href.split('/')[5] || null;

      const [posts, setPosts] = useState(initialPosts);

      const [filteredPosts, setFilteredPosts] = useState(posts);

      const [showCategories, setShowCategories] = useState(window.innerWidth > 767);

      useEffect(() => {
        if (posts.length > 0 && category !== '' && category !== null) {
          setFilteredPosts(posts.filter(p => {
            const postTags = p.tags.split(',').map(x => x.trim().toLowerCase());
            return postTags.includes(category);
          }));
        } else {
          setFilteredPosts(posts);
        }
      }, [posts, category]);

      useEffect(() => {
        if (posts) {
          axios.get(`/base/api/ps`).
              then(response => {
                setPosts(response.data.sort((a, b) => b.pid - a.pid));
                const individualCategories = [];
                response.data.forEach(c => {
                  const postCategoryList = c.tags.split(',').
                      map(t => t.toLowerCase().trim());
                  postCategoryList.forEach(cp => {
                    individualCategories.push(cp);
                  });
                });
                const categoriesCounts = individualCategories.reduce(function(map, word) {
                  map[word] = (map[word] || 0) + 1;
                  return map;
                }, Object.create(null));
                let sortableCat = [];
                for (var c in categoriesCounts) {
                  sortableCat.push([c, categoriesCounts[c]]);
                }
                sortableCat.sort(function(a, b) {
                  return b[1] - a[1];
                });
                let sortableCatMap = {}
                setCategories(sortableCat.map(x => {
                  sortableCatMap[x[0]] = x[1]
                }));
                setCategories(sortableCatMap);
              }).
              catch(error => console.error(
                  `There was an error retrieving the list: ${error}`));
        }
      }, []);

      const categoryBox =
          <div style={{display: showCategories ? 'block' : 'none'}}>
            {
              Object.keys(categories).map(t => {
                const cColor = hexColors[Math.floor(
                    Math.random() * hexColors.length)];
                return <Link style={{
                  fontSize: '1rem',
                  padding: '3px',
                  color: 'black',
                  textShadow: `0 0 3px ${cColor}`,
                }} className="categoryText" key={t}
                             to={'/blog/type/' + t}> {t}:{categories[t]} </Link>;
              })
            }
          </div>;

      if (posts.length === 0) {
        return <LayoutContainer>
          <Container className={'middleCenter'}>
            <Spinner animation={'grow'} size={'lg'}/>
          </Container>
        </LayoutContainer>;
      }

      return (
          <LayoutContainer>
            <Helmet titleTemplate="Kulfi's thoughts - %s">
              <meta charSet="utf-8"/>
              <title>Everything..</title>
              <meta id="og-title" property="og:title" content="Everything.."/>
            </Helmet>
            <Container fluid>
              <Row>
                <div className="notion">
                  <Row>
                    <Col style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}>
                      <Link to="/" style={{
                        textDecoration: 'none',
                        color: 'black',
                        fontSize: '3rem'
                      }}>
                        Rohit's blog
                      </Link>
                    </Col>
                    <Col md={4} style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                      <Link to="/blog/me" className={'notion-who'}>
                        <Button style={{
                          height: '100%',
                          width: '100%',
                          fontSize: '1rem',
                        }}
                                variant="outline-light">
                          Who
                        </Button>
                      </Link>
                      <Link to="/blog/new" className={'notion-who'}>
                        <Button
                            style={{fontSize: '1rem', marginLeft: '10px'}}
                            variant="outline-warning">
                          New
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Col md={2}
                     style={{display: 'inline', fontStyle: 'min(2vw, 12px)'}}>
                  <Row>
                    <Button style={{
                      height: '100%',
                      fontSize: '1rem',
                    }}
                            variant="outline-info"
                            onClick={() => setShowCategories(!showCategories)}>
                      Topics
                    </Button>
                  </Row>
                  <Row>
                    {categoryBox}
                  </Row>
                </Col>
                <Col md={10}>
                  <PostTable2 posts={filteredPosts} category={category}/>
                </Col>
              </Row>
            </Container>
          </LayoutContainer>
      );
    }
;

export default BlogHome;
